.sidebar,
.off-canvas-sidebar {
  .logo-img {
    width: 42px;
    max-height: 42px;

    img {
      width: 42px;
      top: 10px;
      left: 18px;
      position: absolute;
    }
  }
  .nav span {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    -ms-transition: none !important;
    transition: none !important;
  }
}
.main-panel {
  .main-content {
    padding: 0 30px 110px;
    min-height: calc(100vh - 123px);
    margin-top: -30px;
    background-image: url("../../../img/bckgSidebar.png");
    background-size: 20%;
    background-repeat: no-repeat;
    background-position: bottom right;
  }

  .navbar {
    margin-bottom: 0;
  }
}
.panel-header {
  //@include panel-gradient($black-color);
}
