@import "now-ui-dashboard/variables";

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 0s 600000s, color 0s 600000s;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

.angular2-smart-pagination-nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.2rem;
}

.modal-open .wrapper {
  opacity: 0.7;
  filter: blur(2px);
  transition: filter 0.3s ease-in-out;
}

.bck-header-modal-primary {
  background-color: $primary-color;
  color: #fff;

  .close {
    color: #fff !important;
    opacity: 1;
    top: 20px !important;
  }
}

.input-with-icon {
  position: relative;
  display: flex;
  align-items: center;

  input.form-control {
    padding: 10px 30px;
  }

  i {
    position: absolute;
    left: 10px;
    color: #3c3c3c;
    font-size: 16px;
  }
}

.form-control[disabled] {
  background-color: transparent;
  opacity: 0.8;

  & ~ i {
    color: #ccccd3;
  }
}

.wrap-avatar {
  width: 170px;
  height: 170px;
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid;
  padding: 20px;

  .wrap-avatar__img {
    object-fit: cover;
  }
}

.wrap-upload-file {
  position: relative;

  .drop_box {
    position: absolute;
    bottom: 30px;

    i {
      color: #3c3c3c;
    }

    .btn {
      background-color: #ffe6e9;
      color: #fff;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      font-size: 30px;
    }

    input {
      background-color: #e2e2e2;
      border: none;
      outline: none;
      display: none;
    }
  }

  p {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 12px;
    color: #a3a3a3;
    text-align: center;
  }
}

/* ====================================== */
// TABLE
/* ====================================== */
table {
  margin-bottom: 30px;

  select {
    background: #fff !important;
  }

  thead {
    tr {
      th {
        background-color: $table-header;
        text-transform: uppercase;
        padding: 0.8em 1em !important;
        font-family: Josefin Sans, sans-serif;
        line-height: 1.4;

        &.angular2-smart-th.dettaglio {
          text-align: right;
        }

        select,
        input {
          background: #fff !important;
        }
      }
    }
  }

  tbody {
    tr {
      &:hover {
        background-color: rgba($primary-color, 0.05);

        td {
          color: $sidebar-color;
        }
      }

      td {
        border-bottom: 1px solid $table-header;
        font-family: Josefin Sans, sans-serif;
        font-size: 14px;
        color: #5f5f60;
        padding: 0.3em 1em !important;

        app-action-table {
          float: right;
        }
      }
    }

    tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}

.angular2-smart-th.description {
  color: #404040 !important;
}

tr.agent-suspended.angular2-smart-row {
  background: #e4e4e4;

  td:not(:last-child) {
    div {
      opacity: 0.6;
    }
  }
}

span.angular2-smart-sort {
  white-space: nowrap;
}

app-action-table {
  display: flex;
  align-items: center;
}

.app-action-table-btn {
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  i {
    color: #404040;
  }
}

.table-detail-order {
  table {
    thead {
      tr {
        th:nth-of-type(2) {
          width: 45%;
        }

        th:not(th:nth-of-type(1), th:nth-of-type(2)) {
          text-align: center;
        }

        th {
          font-size: 12px;
        }
      }
    }

    tbody {
      tr {
        td:not(td:nth-of-type(1), td:nth-of-type(2)) {
          text-align: center;
        }
      }
    }
  }
}

/* end table */

.modal {
  z-index: 9999;
}

.accordion-item {
  margin-bottom: 10px;

  .accordion-header {
    .accordion-button {
      margin: 0;
    }

    padding: 0;

    .title-product {
      color: #1e1f18;
      font-size: 14px;
      font-weight: 500;

      .title-product__code {
        width: 70px;
        display: inline-block;

        @media screen and (max-width: 992px) {
          width: 50px;
        }
      }

      .title-product__description {
      }
    }
  }

  accordion-body {
    padding: 15px 10px 0 30px;
  }
}

.accordion-button.collapsed a {
  color: #8f8f8f !important;
}

.accordion-button.collapsed a i.fa-plus {
  color: rgb(14 125 223) !important;
}

.accordion-button.collapsed a i.arrows-1_minimal-down {
  color: #114881 !important;
}

.accordion-button.collapsed a i.fa-eye {
  color: #114881 !important;
}

.accordion-button:not(.collapsed) a {
  font-size: 16px;
  font-weight: 600;
}

.accordion-button:not(.collapsed) a .title-product {
  font-weight: 600;
}

.accordion-button:not(.collapsed) a .title-product,
.accordion-button:not(.collapsed) a i {
  color: #114881 !important;
}

.action-related-products .btn-simple {
  border: none;
  margin: 0;
  padding: 0px 10px;
}

.table-related-products {
  thead {
    tr {
      th {
        padding: 5px;
        font-size: 14px;
        font-weight: 400;
        color: grey;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 5px;
        font-size: 13px;
      }
    }
  }
}

.modal-header .title span {
  border-bottom: 1px solid #114881;
}

.card-with-background {
  background-image: linear-gradient(to top, transparent, #fff),
    url("../img/bck-icon-transparent.png");
  background-size: 20%;
  background-repeat: no-repeat;
  background-position: bottom left;

  input.form-control {
    background: #fff;
  }
}

i.custom-icon:before {
  content: "";
  background-size: cover;
  background-repeat: no-repeat;
  width: 25px;
  height: 25px;
  display: block;
}

i.users_profile:before {
  background-image: url("../img/profilo.svg");
}

i.icon-categories:before {
  background-image: url("../img/categorie.svg");
}

i.push-notification:before {
  background-image: url("../img/notifiche_push.svg");
}

i.set-notification:before {
  background-image: url("../img/gelocalizzazione-icon.svg");
}

i.custom-icon.color-base:before {
  filter: invert(48%) sepia(98%) saturate(546%) hue-rotate(309deg)
    brightness(101%) contrast(108%);
}

.sidebar .nav li.active > a:not([data-toggle="collapse"]) i.custom-icon:before,
.off-canvas-sidebar
  .nav
  li.active
  > a:not([data-toggle="collapse"])
  i.custom-icon:before {
  filter: invert(48%) sepia(98%) saturate(546%) hue-rotate(309deg)
    brightness(101%) contrast(108%);
}

.accordion-item .accordion-header .accordion-button[aria-expanded="true"] a i {
  transform: none;
}

.accordion-item
  .accordion-header
  .accordion-button[aria-expanded="true"]
  a
  i.arrows-1_minimal-down {
  transform: rotate(180deg);
}

textarea.form-control {
  min-height: 160px;
  background-image: repeating-linear-gradient(
    to bottom,
    transparent,
    transparent 38px,
    rgb(0 0 0 / 24%) 39px
  );
  line-height: 40px;
  resize: none;
  border: 0;
  padding: 0;
  border-bottom: none;
}

textarea.form-control:focus {
  border: none;
  background-image: repeating-linear-gradient(
    to bottom,
    transparent,
    transparent 38px,
    $primary-color 39px
  );
}

.pointer {
  cursor: pointer;
}

// datapicker

.dp-hidden {
  width: 0;
  margin: 0;
  border: none;
  padding: 0;
}

.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}

.custom-day.focused {
  background-color: #e6e6e6;
}

.custom-day.range,
.custom-day:hover {
  background-color: $primary-color;
  color: #fff;
}

.custom-day.faded {
  background-color: $primary-color;
  color: #fff;
}

/* ====================================== */
// input upload image
/* ====================================== */
.upload-img-box {
  .upload-img-box__image-box {
    width: 100%;
    height: 250px;
    background-image: url("../img/image_placeholder.jpg");
    background-size: contain;
    background-position: center;
    position: relative;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}

/* ====================================== */
// spinner
/* ====================================== */
.wrap-spinner {
  position: fixed;
  z-index: 9999;
  top: 0;
  width: calc(100% - 260px);
  height: 100%;
  background: #1a2a393b;
}

/* ====================================== */
// carousel prizes products
/* ====================================== */
.gift-product {
  .carousel {
    padding: 20px;

    .carousel-inner {
      box-shadow: none !important;

      .carousel-item {
        .visually-hidden {
          display: none;
        }
      }
    }

    .carousel-indicators {
      width: 258px;
      margin: -10px auto;
      gap: 5px;

      button {
        flex: 1px;
        border: none;
        max-width: 45px;
        border-radius: 2px;
        background-color: #bdbdbd;
        padding: 2px 1rem;
      }

      button.active {
        background-color: $primary-color;
      }
    }
  }
}

/* ====================================== */
// BACK BUTTON
/* ====================================== */
.back-btn {
  background-color: transparent;
  padding: 0;
  margin-right: 10px;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent !important;
  }
}

/* ====================================== */
// MODAL INPUT SEARCH
/* ====================================== */
.modal-input-search {
  .modal-input-search__dialog {
    .modal-content {
      min-height: 700px;

      .modal-header {
      }

      .modal-body {
        .input-search {
          position: relative;

          input {
            padding: 5px 0px 5px 35px;
            border-radius: 20px;
            border: 1px solid dimgrey;
          }

          svg {
            position: absolute;
            top: 10px;
            left: 15px;
          }
        }
      }
    }
  }
}

// footer

app-footer {
  position: fixed;
  bottom: 0;
  width: calc(100% - 260px);
}

// CTA Font Awesome icons colors

.actions-icons {
  margin-right: 8px;
  font-size: 16px;
}

.actions-link:hover {
  text-decoration: none !important;
}

.fa-mobile {
  font-size: 20px;
}

.fa-turquoise {
  color: rgb(14 125 223);
}

.fa-blue {
  color: #3a6795;
}

.fa-red {
  color: #fe4242;
}

.highlight {
  background-color: rgb(113, 163, 216);
}

// Tabs wrapper full-size

.nav .tab-content {
  width: 100%;
  padding: 0 2rem;
}

.tabs-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 0;
  margin: 0 auto;
  padding: 0 15px;
}

/* ====================================== */
// ORDER DETAIL
/* ====================================== */
.document-header__row {
  align-items: center;

  p {
    font-size: 18px;
    margin-bottom: 0;
  }

  label {
    color: #6e7173;
    width: 100px;
    margin-right: 10px;
    margin-bottom: 0;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
  }

  span {
    font-size: 16px;
  }
}

/* ====================================== */
// THREAD
/* ====================================== */
span.tox-statusbar__branding {
  display: none;
}

.post {
  .post__question-block {
    background-color: #f4f4f4;
    margin: -24px -24px;
    padding: 0px 20px;
  }

  .post__label {
    color: #828286;

    b {
      color: #114881;
    }
  }

  .post-title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
  }

  .post-question {
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
  }

  .post-body {
    .author {
      color: #828286;
      font-size: 16px;
      font-weight: 600;

      b {
        color: $primary-color;
      }
    }

    .post-state {
      font-size: 16px;
      font-weight: 600;

      i {
        font-size: 22px;
      }
    }
  }

  .post-answer {
    .publication-date {
      background-color: #114881b0;
      padding: 5px;
      color: #fff;
      font-size: 18px;
    }

    p {
      font-size: 20px;
      line-height: 26px;
      font-weight: 400;
      margin-bottom: 0;
    }
  }
}
